import { useRouter } from 'next/router';
import { Box } from '@xstyled/styled-components';
import Seo from '@/SEO';
import Header from '@/Header';
import Footer from '@/Footer';
import MonochromeButton from '@/UI/Button/Variants/Monochrome';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import ErrorLayout from '@/UI/Error/ErrorLayout' 
export default function ErrorPage({ statusCode, title, text, image }) {
  const router = useRouter();

  return (
    <>
      <Seo seo={{ title }} />
      <Header />
      <ErrorLayout
        statusCode={statusCode}
        title={title}
        text={text}
        image={image}
      
      />
      <Footer />
    </>
  );
}
