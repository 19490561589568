import { useRouter } from 'next/router';
import { Box } from '@xstyled/styled-components';

import MonochromeButton from '@/UI/Button/Variants/Monochrome';
import { FlexBox } from '@/UI/Primitives/FlexBox';

const ErrorLayout = ({ statusCode, title, text, image }) => {
  const router = useRouter();

  return (
    <>
      <FlexBox
        as="section"
        w="100%"
        minH="80vh"
        justify="center"
        backgroundImage="url(/images/error_page_bg.png)"
        backgroundRepeat="no-repeat"
        backgroundPosition={{ _: 'center', desktop: 'left -300px center' }}
        backgroundSize={{ _: 'cover', desktop: 'initial' }}
      >
        <FlexBox dir="column" align="center" mt={64}>
          <Box as="h1" fontSize={74} fontWeight={700} color="gray-300">
            {statusCode}
          </Box>
          <p>{text}</p>
          <Box as="span" role="img" aria-label={text} mt={96}>
            {image}
          </Box>
          <MonochromeButton
            p="8px 72px"
            mt={72}
            onClick={() => router.push('/')}
          >
            Tagasi avalehele
          </MonochromeButton>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default ErrorLayout;
